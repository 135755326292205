import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import { Listing, Wrapper, Title } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Pattern from '../components/Pattern'
import LogoOne from '../components/LogoOne'


import * as styles from "../styles/global.module.css"


const Hero = styled.header`
  background-color: #707070;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  max-height: 900px;
`

const HeroInner = styled(Wrapper)`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2rem;
    z-index: 2;
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
`

const TextHero = styled.div`
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  max-width: 500px;
  left: 50%;
  margin-left: -250px;

  @media screen and (max-width: 39.9375em) {
    max-width: 320px;
    margin-left: -160px;
  }

  p {
    line-height: 1.3;
    font-size: 0.8rem;
  }

  h2 {
    font-size: 2.2rem;
    color: #fff;
  }
`

const globalStyle = css`
  :root {
    --section-color: #003b71;
    --section-secondary-color: #001e38;
  }

  .st0 {
    fill: #fff;
  }
`


const ColabWrapper = styled.main`
  background: linear-gradient(to bottom,rgb(112 112 112) 40%,rgb(75 75 75) 50%,rgb(30 30 30) 100%);
  padding-bottom: 5rem;

  .grid-container {
    // max-width: 68rem;
  }
`

const Colaboradores = ({ data: { colaboradores, social, posts, projects }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <Global styles={globalStyle} />
      <Hero>
        <div className={styles.heroimage}>
          <div className={styles.patternTop}>
            <div className={styles.patternWrapper} style={{backgroundImage: `url("${colaboradores.data.hero_image?.url}")`, backgroundPosition: 'center', backgroundSize: 'cover', height: '100%'}}>
              {/* <Pattern /> */}
            </div>
          </div>
          <HeroInner className="grid-container">
            <TextHero>
              <h2>{colaboradores.data.title_page.text}</h2>
              <PrismicRichText field={
                  colaboradores.data.hero_description.richText
                }
              />
              
            </TextHero>
          </HeroInner>
        </div>
      </Hero>
      <ColabWrapper id={website.skipNavId}>

        <section id="one" className={styles.sectionColabs + ' ' + styles.one + " grid-container"}>
          <div className={styles.colab + ' grid-x grid-padding-x'}>
            <div className={styles.order3 + " large-4 medium-4 small-12"}>
              <div className="text-center align-middle" style={{ padding: '25px 0' }}>
                <LogoOne />
              </div>
              <div className={styles.textColab}>
                <PrismicRichText field={
                  colaboradores.data.about_one_development.richText
                }
                />
              </div>
              <div className={styles.ctaColab}>
                <div className='text-center'>
                  <a className={styles.ctaButton + ' ' + styles.colabButton + ' ' + styles.invertedBu + ' button'} href="https://odg.com.mx/" target="_blank"><span className={styles.contentSpan} data-text={locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}><span>{locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}</span></span></a>
                </div>
              </div>
            </div>
            <div className={styles.imageCont + " large-8 medium-8 small-12"} style={{ minHeight: '300px' }}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{ backgroundImage: 'url("' + `${colaboradores.data.photo_one.url}` + '")' }} />
              </div>
            </div>
          </div>
        </section>

        <section id="jsa" className={styles.sectionColabs + ' ' + styles.jsa + " grid-container"}>
          <div className={styles.colab + ' grid-x grid-padding-x'}>
            <div className={styles.order2 + " large-8 medium-8 small-12"}>
            </div>
            <div className="large-4 medium-4 hide-for-small-only text-center align-middle" style={{ padding: '25px 0' }}>
            </div>
            <div className={styles.imageCont + " large-8 medium-8 small-12"} style={{ minHeight: '300px' }}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{ backgroundImage: 'url(' + `${colaboradores.data.photo_jsa.url}` + ')' }} />
              </div>
            </div>
            <div className={styles.order3 + " large-4 medium-4 small-12"}>
              <h2 style={{ textAlign: 'center', color: '#fff', padding: '2rem 0', margin: '0' }}>JSa</h2>

              <div className={styles.textColab}>
                <PrismicRichText field={
                  colaboradores.data.about_jsa.richText
                }
                />
              </div>
              <div className={styles.ctaColab}>
                <div className='text-center'>
                  <a className={styles.ctaButton + ' ' + styles.colabButton + ' button'} href="http://www.jsa.com.mx/" target="_blank"><span className={styles.contentSpan} data-text={locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}><span>{locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}</span></span></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="markzeff" className={styles.sectionColabs + ' ' + styles.markzeff + " grid-container"}>
          <div className={styles.colab + ' grid-x grid-padding-x'}>
            <div className={styles.order3 + " large-4 medium-4 small-12"}>
              <h2 style={{ textAlign: 'center', color: '#fff', padding: '2rem 0', margin: '0' }}>MARKZEFF</h2>
              <div className={styles.textColab}>
                <PrismicRichText field={
                  colaboradores.data.about_markzeff.richText
                }
                />
              </div>
              <div className={styles.ctaColab}>
                <div className='text-center'>
                  <a className={styles.ctaButton + ' ' + styles.colabButton + ' button'} href="https://markzeff.com/" target="_blank"><span className={styles.contentSpan} data-text={locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}><span>{locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}</span></span></a>
                </div>
              </div>
            </div>
            <div className={styles.imageCont + " large-8 medium-8 small-12"} style={{ minHeight: '300px' }}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{ backgroundImage: 'url(' + `${colaboradores.data.photo_markzeff.url}` + ')' }} />
              </div>
            </div>
          </div>
        </section>

        <section id="cadena" className={styles.sectionColabs + ' ' + styles.cadena + " grid-container"}>
          <div className={styles.colab + ' grid-x grid-padding-x'}>
            <div className={styles.imageCont + " large-8 medium-8 small-12"} style={{ minHeight: '300px' }}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{ backgroundImage: 'url(' + `${colaboradores.data.photo_cadena.url}` + ')' }} />
              </div>
            </div>
            <div className={styles.order3 + " large-4 medium-4 small-12"}>
              <h2 style={{ textAlign: 'center', color: '#fff', padding: '2rem 0', margin: '0' }}>CADENA + ASOC. CONCEPT DESIGN</h2>

              <div className={styles.textColab}>
                <PrismicRichText field={
                  colaboradores.data.about_cadena.richText
                }
                />
              </div>
              <div className={styles.ctaColab}>
                <div className='text-center'>
                  <a className={styles.ctaButton + ' ' + styles.colabButton + ' button'} href="https://www.cadena-asociados.com/" target="_blank"><span className={styles.contentSpan} data-text={locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}><span>{locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}</span></span></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="s2e" className={styles.sectionColabs + ' ' + styles.markzeff + " grid-container"}>
          <div className={styles.colab + ' grid-x grid-padding-x'}>
            <div className={styles.order3 + " large-4 medium-4 small-12"}>
              <h2 style={{ textAlign: 'center', color: '#fff', padding: '2rem 0', margin: '0' }}>S2E TECHNOLOGIES</h2>
              <div className={styles.textColab}>
                <PrismicRichText field={
                  colaboradores.data.about_s2e.richText
                }
                />
              </div>
              <div className={styles.ctaColab}>
                <div className='text-center'>
                  <a className={styles.ctaButton + ' ' + styles.colabButton + ' button'} href="https://s2etech.com/" target="_blank"><span className={styles.contentSpan} data-text={locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}><span>{locale === 'es-mx' ? ('Visitar sitio web') : ('Visit Website')}</span></span></a>
                </div>
              </div>
            </div>
            <div className={styles.imageCont + " large-8 medium-8 small-12"} style={{ minHeight: '300px' }}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{ backgroundImage: 'url(' + `${colaboradores.data.photo_s2e.url}` + ')' }} />
              </div>
            </div>
          </div>
        </section>

      </ColabWrapper>
    </>
  )
}

export default Colaboradores

Colaboradores.propTypes = {
  data: PropTypes.shape({
    colaboradores: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ColaboradoresQuery($locale: String!) {
    colaboradores: prismicColaboradores(lang: { eq: $locale }) {
      data {
        title_page {
          text
        }
        photo_one {
          url
        }
        hero_image {
          url
        }
        photo_markzeff {
          url
        }
        photo_jsa {
          url
        }
        photo_cadena {
          url
        }
        photo_s2e {
          url
        }
        hero_description {
          html
          richText
        }
        about_one_development {
          html
          richText
        }
        about_markzeff {
          html
          richText
        }
        about_jsa {
          html
          richText
        }
        about_cadena {
          html
          richText
        }
        about_s2e {
          html
          richText
        }
      }
    }
  }
`
